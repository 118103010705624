import React from 'react'

export default function ArticlePageTemplate() {
  return <div></div>
}

// import React from 'react'
// import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'
// import { Box, Heading, Text, Image } from 'theme-ui'
// import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
// import Fade from 'react-reveal/Fade'
// import readingTime from 'reading-time'
// import PageHero from '../components/Hero/PageHero'
// import SocialIconsBox from '../components/UIElements/SocialIconsBox'
// import FunFacts from '../components/UIElements/FunFacts'
// import DownloadTheApp from '../components/UIElements/DownloadTheApp'
// import ContentfulBlocks from '../helpers/ContentfulBlocks'
// import { IoCalendarClearOutline } from 'react-icons/io5'
// import ShareLinks from '../components/UIElements/ShareLinks'

// export default function ArticlePageTemplate(props) {
//   const { data, pageContext } = props

//   // depending on the type of article the switch statement will take the context from node and decide which data to fetch
//   const articleTypeKey = () => {
//     switch (pageContext.articleType) {
//       case 'featuredInWineArticles':
//         return 'contentfulTrendingBlogs'

//       case 'wineConnoisseurArticles':
//         return 'contentfulConnoisseurBlogs'

//       default:
//         return ''
//     }
//   }

//   const articleData = data?.[articleTypeKey()]

//   console.log(articleData.content.references)

//   return (
//     <Box sx={styles.container}>
//       <PageHero
//         title={articleData.title}
//         subtitle={articleData.subtitle}
//         text={articleData.description}
//         image={articleData.image[0].fluid}
//         imageDescription={articleData.image[0].description}
//         date={articleData.createdAt}
//         readingTimeText={
//           readingTime(
//             documentToPlainTextString(JSON.parse(articleData.content.raw)),
//           ).text
//         }
//       />
//       <Box sx={styles.row}>
//         <Box sx={styles.articleColumn}>
//           <Box sx={styles.shareArticle}>
//             <Fade left>
//               <ShareLinks url={props.location.href} />
//             </Fade>
//           </Box>

//           <Fade left cascade>
//             <ContentfulBlocks
//               raw={articleData.content.raw}
//               references={articleData.content.references}
//               style={styles.articleBody}
//             />
//           </Fade>

//           <Box sx={styles.blogFooter}>
//             <IoCalendarClearOutline />{' '}
//             <Text as='p'>Last Updated: {articleData.updatedAt}</Text>
//           </Box>
//         </Box>

//         <Box sx={styles.widgetColumn}>
//           <Fade right cascade>
//             <FunFacts style={styles.funFacts} />
//             <DownloadTheApp style={styles.socialIconsBox} />
//             <SocialIconsBox style={styles.socialIconsBox} />
//           </Fade>
//         </Box>
//       </Box>
//     </Box>
//   )
// }

// const styles = {
//   container: {
//     width: '100%',
//   },
//   row: {
//     display: 'flex',
//     flexDirection: ['column', '', 'row'],
//     width: '100%',
//     // maxWidth: '1200px',
//     margin: '0 auto',
//     padding: ['0rem 1rem', '', '1rem 1rem 1rem calc(3% - 0.5rem)'],
//   },
//   articleColumn: {
//     width: ['100%', '', '65%', '70%', '70%'],
//     padding: ['0rem', '', '0rem 1rem 1rem 1.5rem'],
//     // paddingLeft: ['', '', '1.5rem'],
//     // paddingRight: ['', '', '1rem'],
//   },

//   articleBody: {},

//   date: {
//     variant: 'customStyles.iconText',
//     margin: '1rem 0rem',
//   },
//   titleContainer: {},
//   title: {
//     fontSize: ['1rem', '2rem', '3rem', '4rem'],
//   },
//   subtitle: {},
//   blogFooter: {
//     variant: 'customStyles.iconText',
//     borderTop: '1px solid',
//     borderBottom: '1px solid',
//     borderColor: 'secondary',
//     padding: '1rem',
//     margin: '2rem 0rem 2rem',
//     opacity: '0.7',
//   },
//   imageContainer: {
//     backgroundColor: 'white',
//     '.gatsby-image-wrapper': {
//       height: '50vh',
//     },
//   },

//   // ? === widgets ==
//   widgetColumn: {
//     width: ['100%', '', '35%', '30%', '30%'],
//   },
//   funFacts: {
//     maxWidth: 'unset',
//     width: [
//       'calc(100% - 1rem)',
//       '',
//       '',
//       'calc(100% - 2rem)',
//       'calc(100% - 4rem)',
//     ],
//     margin: [
//       '2rem 0.5rem 1rem',
//       '',
//       '0rem 0.5rem 1rem',
//       '0rem 1rem 1rem',
//       '0rem 2rem 2rem',
//     ],
//   },
//   articleBoxes: {
//     maxWidth: 'unset',
//     paddingRight: ['', '', '', '2rem', '4rem'],
//   },
//   socialIconsBox: {
//     width: [
//       'calc(100% - 1rem)',
//       '',
//       '',
//       'calc(100% - 2rem)',
//       'calc(100% - 4rem)',
//     ],
//     margin: [
//       '2rem 0.5rem 1rem',
//       '',
//       '0rem 0.5rem 1rem',
//       '0rem 1rem 1rem',
//       '0rem 2rem 2rem',
//     ],
//   },
// }

// export const query = graphql`
//   query BlogPageQuery($blogId: String!) {
//     contentfulTrendingBlogs(id: { eq: $blogId }) {
//       id
//       updatedAt(formatString: "MMM DD, YYYY")
//       createdAt(formatString: "MMM DD, YYYY")
//       title
//       subtitle
//       description {
//         raw
//       }
//       image {
//         fluid {
//           ...GatsbyContentfulFluid_tracedSVG
//         }
//       }
//       content {
//         raw
//         references {
//           fluid {
//             ...GatsbyContentfulFluid_tracedSVG
//           }
//         }
//       }
//     }
//     contentfulConnoisseurBlogs(id: { eq: $blogId }) {
//       id
//       updatedAt(formatString: "MMM DD, YYYY")
//       createdAt(formatString: "MMM DD, YYYY")
//       title
//       subtitle
//       description {
//         raw
//       }
//       image {
//         fluid {
//           ...GatsbyContentfulFluid_tracedSVG
//         }
//       }
//       content {
//         raw
//         references {
//           fluid {
//             ...GatsbyContentfulFluid_tracedSVG
//           }
//         }
//       }
//     }
//   }
// `
